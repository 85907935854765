import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    title: 'Presto (Prototype)',
    currentProject: '',
    selectedProject: '',
    selectedNavigation: '',
    selectedAction: '',
    currentFocus: '',
    currentStage: 'Abstract Screening',
    currentPublicationId: '1234',
    surveyOne: '',
    surveyTwo: '',
    isLogged: false,
    isLoading: false,
    userName: '',
    showActions: true,
    configurationViewAll: true,
    showHomePage: true,
    citationChange: 1,
    surveyDesignerDummyTest: {},
    isMainCitationSelected: false,
    selectedCitationFilters: [],
    selectedStudyFilters: [],
    selectedOtherPublicationFilters: [],
    selectedDataItems: [],
    isDoubleDataEntry: true,
    isFirstActionVisualization: false,
    isStudyMapping: false,
    cardSelector: false,
    navigation: [
      {
        name: 'admin',
        title: 'Admin',
        path: '/Secure/AdminHome',
        visible: true,
        enabled: true,
        actions: [
          // { name: 'projects', title: 'Projects', path: '/Secure', visible: true, enabled: true },
          { name: 'admin-users', title: 'Users', path: '/Secure/AdminUsers', visible: true, enabled: true },
          { name: 'admin-form-templates', title: 'Form Templates', path: '/Secure/AdminFormTemplates', visible: true, enabled: true }
          // { name: 'backup', title: 'Backup', path: '/Secure', visible: true, enabled: true }
        ]
      },
      {
        name: 'configuration',
        title: 'Configure',
        path: '',
        visible: true,
        enabled: true,
        actions: [
          { name: 'configuration-view-all', title: 'View All', path: '/Secure/ViewAll', visible: true, enabled: true },
          { name: 'configuration-general-info', title: 'General Info', path: '/Secure/GeneralInfo', visible: true, enabled: true },
          { name: 'configuration-picos-criteria', title: 'PICOS criteria', path: '/Secure/PicosCriteria', visible: true, enabled: true },
          { name: 'configuration-stages', title: 'Stages', path: '/Secure/Stages', visible: true, enabled: true },
          { name: 'configuration-studies', title: 'Studies', path: '/Secure/Studies', visible: true, enabled: true },
          { name: 'configuration-forms', title: 'Forms', path: '/Secure/Forms', visible: true, enabled: true },
          { name: 'configuration-users', title: 'Users', path: '/Secure/Users', visible: true, enabled: true },
          { name: 'configuration-reject-reasons', title: 'Exclusion Reasons', path: '/Secure/ExclusionReasons', visible: true, enabled: true },
          { name: 'configuration-keywords-tags', title: 'Keywords/Tags', path: '/Secure/KeywordsTags', visible: true, enabled: true },
          { name: 'configuration-preferences', title: 'Preferences', path: '/Secure/Preferences', visible: true, enabled: true }
        ]
      },
      {
        name: 'import',
        title: 'Import',
        path: '/Secure/ImportHome',
        visible: true,
        enabled: true,
        actions: [
          { name: 'from-file', title: 'Citations/Full Texts', path: '/Secure/ImportFile', visible: true, enabled: true },
          { name: 'from-projects', title: 'Legacy Citations', path: '/Secure/ImportProject', visible: true, enabled: true },
          { name: 'manual', title: 'Manage Citations', path: '/Secure/ImportManual', visible: true, enabled: true }
        ]
      },
      {
        name: 'review',
        title: 'Review',
        path: '/Secure/ManageHome',
        visible: true,
        enabled: true,
        actions: [
          { name: 'batch-review-manage', title: 'Batch Review', path: '/Secure/BatchReview', visible: true, enabled: true },
          { name: 'select', title: 'Individual Review', path: '/Secure/Select', visible: true, enabled: true },
          { name: 'study-mapping', title: 'Study Mapping', path: '/Secure/StudyMapping', visible: true, enabled: true }
        ]
      },
      {
        name: 'reconciliation',
        title: 'Reconciliation',
        path: '/Secure/ManageHome',
        visible: true,
        enabled: true,
        actions: [
          { name: 'stage-discrepancies', title: 'Stage Discrepancies', path: '/Secure/StageDiscrepancies', visible: true, enabled: true },
          { name: 'data-validation', title: 'Data Discrepancies', path: '/Secure/DataDiscrepancies', visible: true, enabled: true }
        ]
      },
      {
        name: 'validation',
        title: 'Validation',
        path: '/Secure/ManageHome',
        visible: true,
        enabled: true,
        actions: [
          { name: 'stage-validation', title: 'Batch Stage', path: '/Secure/BatchStagevalidation', visible: true, enabled: true },
          { name: 'stage-validation', title: 'Individual Stage', path: '/Secure/Stagevalidation', visible: true, enabled: true },
          { name: 'data-validation', title: 'Data', path: '/Secure/DataValidation', visible: true, enabled: true }
        ]
      },
      {
        name: 'reports',
        title: 'Reports',
        path: '/Secure/Reports',
        visible: true,
        enabled: true,
        actions: [
          { name: 'stage-history-by-citation', title: 'Stage History By Citation', path: '/Secure/reports/StageHistoryByCitation', visible: true, enabled: true },
          { name: 'citations-with-annotations-discrepancies', title: 'Citations with Annotations/Discrepancies', path: '/Secure/reports/CitationsWithAnnotationsDiscrepancies', visible: true, enabled: true },
          { name: 'metrics-report', title: 'Metrics Report', path: '/Secure/reports/MetricsReport', visible: true, enabled: true },
          { name: 'potential-duplicates', title: 'Potential Duplicates', path: '/Secure/reports/PotentialDuplicates', visible: true, enabled: true }
        ]
      }
    ]
  }
})
